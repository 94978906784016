import { j as s } from "./jsx-runtime-D0AQ1Mn7.js";
import { useState as v } from "react";
import "./Accordion-DrU-sWJL.js";
import "./Avatar-al9EpjB_.js";
import { B as h } from "./Badge-QBVLNL8O.js";
import { B as f } from "./Button-CAmI0_H8.js";
import "./DropdownMenu-CRQiwey-.js";
import "./Icon-CtH7buGH.js";
import "./Input-CyIITBNc.js";
import { L as j } from "./Label-C4UDFZ-F.js";
import { T as i } from "./Text-CwTWgi3Y.js";
import "./Tooltip-B25WPLZY.js";
import { c as w } from "./utils-CJ9afRe1.js";
import { S as u } from "./Stack-B4QZt_R0.js";
import "./Switch-CXlkMKPd.js";
import "./Toast-4AphDOJm.js";
import "./Toggle-D-drA526.js";
import { A as g } from "./AlertDialog.component-C7J7gpua.js";
import { D as b } from "./Drawer.component-CbbG_sic.js";
import { R as o } from "./RadioGroup.component-wuNUvvMq.js";
import { S as N } from "./Separator.component-CUuwnhk-.js";
import { u as A } from "./useMediaQuery-BLIs2rKV.js";
const J = (e) => {
  var m, c;
  const [n, l] = v(null), a = A("(min-width: 768px)"), r = a ? g : b;
  return /* @__PURE__ */ s.jsx(
    r,
    {
      onOpenChange: e.onOpenChange,
      open: e.open,
      children: /* @__PURE__ */ s.jsxs(
        r.Content,
        {
          className: "max-w-md z-full-screen",
          children: [
            /* @__PURE__ */ s.jsxs(i, { className: "px-3 pt-3", children: [
              "Upload",
              " ",
              e.items.length,
              " ",
              "items to ",
              (m = e.loan) == null ? void 0 : m.shortCode,
              " shoebox?"
            ] }),
            /* @__PURE__ */ s.jsx(N, {}),
            /* @__PURE__ */ s.jsx(r.Header, { className: w("px-4", {
              "text-center items-center justify-center": a,
              "text-left": !a
            }), children: /* @__PURE__ */ s.jsx(i, { size: a ? "base" : "sm", children: "You’re not on this loan. Please select the user's shoebox to add items or select yourself to join loan." }) }),
            /* @__PURE__ */ s.jsxs(
              o,
              {
                onValueChange: (t) => {
                  var d, x;
                  if (t === ((d = e.me) == null ? void 0 : d.id))
                    l(e.me || null);
                  else {
                    const C = (x = e.loan) == null ? void 0 : x.users.find((y) => y.id === t);
                    l(C || null);
                  }
                },
                className: "px-2 flex flex-col gap-5",
                children: [
                  e.me && /* @__PURE__ */ s.jsxs(
                    u,
                    {
                      row: !0,
                      space: "sm",
                      className: "items-center hover:bg-gray-neutral-30 rounded-lg px-3 py-2",
                      children: [
                        /* @__PURE__ */ s.jsx(o.Item, { value: e.me.id, id: e.me.id }),
                        /* @__PURE__ */ s.jsxs(
                          j,
                          {
                            className: "flex-1 gap-1 flex items-center",
                            htmlFor: e.me.id,
                            children: [
                              e.me.givenName,
                              /* @__PURE__ */ s.jsx(i, { size: "sm", variant: "secondary", children: "(Me)" })
                            ]
                          }
                        ),
                        /* @__PURE__ */ s.jsx(h, { variant: "secondary", children: e.me.role })
                      ]
                    }
                  ),
                  /* @__PURE__ */ s.jsx(i, { size: "sm", className: "px-3", children: "Team" }),
                  (c = e.loan) == null ? void 0 : c.users.map((t) => /* @__PURE__ */ s.jsxs(
                    u,
                    {
                      row: !0,
                      space: "sm",
                      className: "items-center hover:bg-gray-neutral-30 rounded-lg px-3 py-2",
                      children: [
                        /* @__PURE__ */ s.jsx(o.Item, { value: t.id, id: t.id }),
                        /* @__PURE__ */ s.jsx(j, { className: "flex-1", htmlFor: t.id, children: t.name }),
                        /* @__PURE__ */ s.jsx(h, { variant: "secondary", children: t.role })
                      ]
                    },
                    t.id
                  ))
                ]
              }
            ),
            /* @__PURE__ */ s.jsx(N, {}),
            /* @__PURE__ */ s.jsxs(g.Footer, { children: [
              /* @__PURE__ */ s.jsx(
                f,
                {
                  onClick: () => {
                    var t;
                    return (t = e.onOpenChange) == null ? void 0 : t.call(e, !1);
                  },
                  className: "w-24",
                  variant: "outline",
                  children: "Cancel"
                }
              ),
              /* @__PURE__ */ s.jsx(
                f,
                {
                  className: "w-24",
                  onClick: () => {
                    n && e.loan && e.onConfirm(e.loan, n, e.items);
                  },
                  disabled: !n,
                  children: "Add"
                }
              )
            ] })
          ]
        }
      )
    }
  );
};
export {
  J as A
};
